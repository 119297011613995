import { extendTheme } from "@chakra-ui/react";

import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/700.css";


const theme = {
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  colors: {
    primary: {
      500: "#610323FF",
      900: "#610323FF"
    },
    text: {
      primary: "#1F1A1A",
    },
    white: "#FFFFFF",
  },
  fonts: {
    heading: 'DM Sans',
    body: 'DM Sans'
  }
};


export default extendTheme( theme );
